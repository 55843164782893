import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';
import Mousetrap from 'mousetrap';
import { useInView } from 'react-intersection-observer';
import slugify from 'helpers/slugify';
import removeBr from 'helpers/removeBr';
import Billboard from 'components/billboard';
import Metas from 'components/metas';
import SmartLink from 'components/smartLink';
import LazyImage from 'components/lazyImage';
import { Choosy, Option } from 'components/choosy';
import SearchInput from 'components/searchInput';
import { ReactComponent as SearchIcon } from 'images/icon-search.svg';
import { ReactComponent as XIcon } from 'images/icon-x.svg';
import styles from './resource-page.module.scss';

const isClient = typeof window !== 'undefined';

function ResourceCard({
  image,
  category,
  title,
  description,
  link,
  linkText,
  featured,
  className,
}) {
  const [containerRef, inView] = useInView({ triggerOnce: true });
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  return (
    <article
      ref={containerRef}
      className={classnames(styles.card, className, {
        [styles.visible]:
          (featured && imageIsLoaded && inView) ||
          !featured & inView ||
          !image & inView,
      })}
    >
      {featured && (
        <div className={styles.card__image}>
          {image && (
            <LazyImage
              src={`${image.file.url}?w=1080`}
              onLoad={() => setImageIsLoaded(true)}
              alt={image.title}
            />
          )}
        </div>
      )}
      <div className={styles.card__contents}>
        <div className={styles.card__category}>{category}</div>
        <h2 className={styles.card__title}>{title}</h2>
        <div className={styles.card__description}>
          {description ? description.description : null}
        </div>
        <SmartLink className={styles.card__button} to={link}>
          {linkText}
        </SmartLink>
      </div>
    </article>
  );
}

export default function ResourcePage() {
  /*
   *   Query resources
   */
  const resourcesData = useStaticQuery(graphql`
    query WhitePapersQuery {
      allContentfulResource(
        sort: { order: DESC, fields: updatedAt }
        filter: {
          excludeFromResourcesPage: { ne: true }
          resourceType: { eq: "White paper" }
        }
      ) {
        nodes {
          ... on Node {
            ... on ContentfulResource {
              id
              title
              resourceType
              featured
              image {
                file {
                  url
                }
                title
              }
              link
              description {
                description
              }
              category {
                title
                id
              }
            }
          }
        }
      }
    }
  `);

  const [currentCat, setCurrentCat] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const searchButtonRef = useRef(null);
  const searchInputRef = useRef(null);
  const searchContainerRef = useRef(null);
  const resources = resourcesData.allContentfulResource.nodes;
  const filteredResources = resources.filter(
    (p) => p.category.id === currentCat || currentCat === null
  );
  const categories = resources.reduce(
    (acc, curr) => ({ ...acc, [curr.category.id]: curr.category }),
    {}
  );
  const currentCatTitle = currentCat
    ? categories[currentCat].title
    : 'All Resources';

  /*
   *   Set current Category
   */
  function setCurrentCategory(id) {
    setTimeout(() => setCurrentCat(id), 50);
  }

  /*
   *   Display search results
   */
  function handleSearch(results) {
    setSearchResults(results);
  }

  /*
   *   Handle open search doc click
   */
  const handleSearchDocClick = useCallback(
    (evt) => {
      if (searchContainerRef.current.contains(evt.target)) return;
      closeSearch();
    },
    [] //eslint-disable-line
  );

  /*
   *   Open Search
   */
  function openSearch() {
    setSearchIsOpen(true);
    Mousetrap.bind('esc', closeSearch);
    document.addEventListener('click', handleSearchDocClick);
    searchInputRef.current.focus();
  }

  /*
   *   Close Search
   */
  function closeSearch() {
    setSearchIsOpen(false);
    searchButtonRef.current.focus();
    Mousetrap.unbind('esc');
    document.removeEventListener('click', handleSearchDocClick);
  }

  /*
   *   House keeping
   */
  useEffect(() => {
    return () => {
      Mousetrap.unbind('esc');
      document.removeEventListener('click', handleSearchDocClick);
    };
  }, [handleSearchDocClick]);

  /*
   *   hash-based tab nav
   */
  useEffect(() => {
    if (
      isClient &&
      window.location.hash &&
      slugify(currentCatTitle).toLowerCase() !== slugify(window.location.hash)
    ) {
      const category = Object.values(categories).filter(
        (cat) =>
          slugify(cat.title).toLowerCase() === slugify(window.location.hash)
      )[0];
      if (category) setCurrentCat(category.id);
    }
  }, [categories, currentCatTitle]);

  /*
   *   Guides
   */
  const resourceEls = filteredResources
    .sort((a, b) => (a.featured === b.featured ? 0 : a.featured ? -1 : 1))
    .map((r, i) => (
      <ResourceCard
        key={r.id}
        title={r.title}
        category={r.category && removeBr(r.category.title)}
        image={r.image}
        link={r.link}
        linkText="Read the guide"
        description={r.description}
        featured={i < 2 && !currentCat}
      />
    ));

  const footerText = {
    json: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Ready to engage, support, and grow every learner?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
  };

  /*
   *   Category els
   */
  const choosyCategoryEls = Object.keys(categories).map((c) => {
    const cat = categories[c];
    return (
      <Option
        key={cat.id}
        className={classnames(styles.choosy__option, {
          [styles.current]: currentCat === cat.id,
        })}
        onClick={() => setCurrentCategory(cat.id)}
      >
        {removeBr(cat.title)}
      </Option>
    );
  });

  /*
   *   Search input
   */
  const searchInputEl = useMemo(
    () => (
      <SearchInput
        tabIndex={searchIsOpen ? null : -1}
        indexIdField="id"
        indexFields={[
          'title',
          ['category', 'title'],
          ['description', 'description'],
          ['author', 'name'],
        ]}
        documents={resources}
        className={classnames(styles.search__input, 'mousetrap')}
        inputRef={searchInputRef}
        onSearch={handleSearch}
        clear={searchIsOpen}
      />
    ),
    [resources, searchIsOpen]
  );

  /*
   *   Search Result Els
   */
  const searchResultEls = searchResults.map((r) => (
    <SmartLink to={r.link} className={styles.search__result} key={r.id}>
      {r.title}
    </SmartLink>
  ));

  /*
   *   Search Container El
   */
  const searchEl = (
    <div
      className={classnames(styles.search, {
        [styles.open]: searchIsOpen,
      })}
      ref={searchContainerRef}
    >
      <div className={styles.search__form}>
        <SearchIcon />
        <div className={styles.search__form_inner}>
          {searchInputEl}
          <button
            className={styles.search__close}
            onClick={closeSearch}
            tabIndex={searchIsOpen ? null : -1}
          >
            <XIcon />
          </button>
        </div>
      </div>
      <div className={styles.search__results}>{searchResultEls}</div>
    </div>
  );

  return (
    <>
      <Metas title="Resources" />

      <section className={classnames(styles.section, styles.section_blue)}>
        <div className={styles.section__inner}>
          <h1 className={styles.section__title}>Guides</h1>

          <div className={styles.menu}>
            <Choosy className={styles.choosy} label={currentCatTitle}>
              {choosyCategoryEls}
            </Choosy>

            <button
              ref={searchButtonRef}
              className={styles.search_button}
              onClick={openSearch}
            >
              <SearchIcon />
            </button>

            {searchEl}
          </div>

          <div className={classnames(styles.cards)}>{resourceEls}</div>
        </div>
      </section>

      <Billboard
        title="Inspire the desire to learn."
        subtitle={footerText}
        buttonText="Contact us"
        buttonUrl="https://go.newsela.com/contact"
        backgroundColor="Blue"
      />
    </>
  );
}
